import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "en-automation-overview",
  "title": "Intelligent Automation",
  "seo": {
    "title": "Intelligent Automation",
    "descr": "-"
  },
  "bg": "/img/bg-automation.jpg",
  "summaryList": [{
    "text": "Intelligent Automation is an area within AI (Artificial Intelligence) that includes different technologies and concepts such as RPA (Robotic Process Automation), Machine Learning, Deep Learning, Chatbots, Computer Vision, NLP (Natural Language Processing) and other AI-tools. Intelligent Automation are technologies that are used to automate processes of different kinds. These technologies combined, such as RPA and Machine Learning, are the core of automation and contribute to smarter and more powerful solutions. Intelligent Automation is changing the way companies and organisations are run. Taking on from the digitalisation that has been going on for many years, Intelligent Automation is the next step on the journey."
  }, {
    "text": "The main purpose of Intelligent Automation is to improve the employee- and customer experience while increasing productivity. When companies and organisations automate repetitive, time consuming and tedious tasks, employees get time over to perform more stimulating and creative tasks such as strategic work or customer related processes.This contributes to the customer getting a faster interaction and better response from the company. In addition to achieving time and cost savings, Intelligent Automation also contributes to an improved quality, capacity and efficiency within the organisation."
  }],
  "pink": {
    "title": "Get started!",
    "text": "Once the processes to automate have been selected, the adaptation work begins. Join us in a world of new technical possibilities, tailored to your needs. Contact us below!",
    "buttonText": "Contact us",
    "buttonPath": "contact"
  },
  "endSection": {
    "title": "Our assistants",
    "icon": "/img/cost.png",
    "buttonText": "Go to",
    "buttonPath": "digitalassistants"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      